import React from 'react';
import {
  Container, Typography, Box, Grid, Rating,
} from '@material-ui/core';

export default function HowItWorks(props) {
  return (
    <Box
      sx={{
        pt: 10,
        pb: 10,
        backgroundColor: 'background.default',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h4"
              fontFamily="Cormorant SC"
              color="textPrimary"
              sx={{ pb: 3 }}
            >
              E o que os alunos estão dizendo?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Grid>
              <Rating
                readOnly
                value={5}
              />
            </Grid>
            <Grid>
              <Typography
                variant="h4"
                fontFamily="Cormorant SC"
                color="textPrimary"
              >
                Felipe Santos
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                “Fiquei muito satisfeito quando encontrei o modelo Laborem de ensino, completamente
                diferente do que é apresentado no mercado em geral, muito mais do que dar as
                ferramentas o Lucas nos ensina a utilizá-las da maneira correta, em seus
                devidos contextos.”
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Grid>
              <Rating
                readOnly
                value={4.5}
                precision={0.5}
              />
            </Grid>
            <Grid>
              <Typography
                variant="h4"
                fontFamily="Cormorant SC"
                color="textPrimary"
              >
                Douglas Paulan
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                “Abrangente e direto. Parabéns pelo excelente treinamento e didática”
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Grid>
              <Rating
                readOnly
                value={4.5}
                precision={0.5}
              />
            </Grid>
            <Grid>
              <Typography
                variant="h4"
                fontFamily="Cormorant SC"
                color="textPrimary"
              >
                Edson Carlos
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                “Definitivamente a escolha certa, inclusive recomendei para minha esposa,
                ela está migrando e tenho certeza que o treinamento da Laborem irá agregar
                demais no futuro profissional que ela almeja”
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Grid>
              <Rating
                readOnly
                value={5}
                precision={0.5}
              />
            </Grid>
            <Grid>
              <Typography
                variant="h4"
                fontFamily="Cormorant SC"
                color="textPrimary"
              >
                Daniel Araújo
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                “Excelente treinamento. Além de não terem aulas monótonas o professor tem grande
                conhecimento na área e envolve a teoria com a prática com maestria”
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
