/* eslint-disable max-len */
import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@material-ui/styles';
import { ThemeContext } from '../../../context/ThemeContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(91.49deg, #C31432 7.06%, #240B36 100%)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  outlined: {
    background: 'transparent',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'linear-gradient(91.49deg, #C31432 7.06%, #240B36 100%)',
    height: 48,
    padding: '0 30px',
  },
  outlinedDark: {
    background: 'rgba(255, 105, 135, .1)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: '#fff',
    height: 48,
    padding: '0 30px',
  },
});
function CustomVariant({ handleOpen }) {
  const classes = useStyles();
  const { theme } = useContext(ThemeContext);
  if (!theme) {
    return <Button onClick={handleOpen} className={classes.outlined}>ASSINE NOSSA NEWSLETTER</Button>;
  }

  return <Button onClick={handleOpen} className={classes.outlinedDark}>ASSINE NOSSA NEWSLETTER</Button>;
}
export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <CustomVariant handleOpen={handleOpen}>ASSINE NOSSA NEWSLETTER</CustomVariant>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <iframe title="form"
            width="400px"
            height="600px"
            src="https://95ed8e72.sibforms.com/serve/MUIEAFvs9gTL4ozceIiW7XOadf8BilvtK5IdA62BMPHhrtZJ2OdJlT5_LT4tNN44UCn_uU3OHUS0u4nBa9P8vKF2hZe9SIbKp5z1RRPxaReoShJ0Ayshk5yqxDAk1CamXHz_q-VF0pkOIotmKTeAK8oOX5z5-Z8NjwhMmc6rLAmRvsWuJhKk-5qB0sprL2Wrz6o7hmK8T9mRJ7-R"
            frameBorder="0"
            scrolling="auto"
            allowFullScreen
            style={{
              display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%',
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}
