import React from 'react';
import HomeHero from './HomeComp/HomeHero';
import About from './HomeComp/About';
import WhatIsPassed from './HomeComp/WhatIsPassed';
import BannerIcon from './HomeComp/BannerIcon';
import HowItWorks from './HomeComp/HowItWorks';
import Feedbacks from './HomeComp/Feedbacks';
import Footer from './HomeComp/Footer';
import FAQ from './HomeComp/FAQ';
import Pricing from './HomeComp/Pricing';
import Effect from './HomeComp/Effect';
import MainLayout from '../Layout/MainLayout';

export default function Home() {
  return (
    <MainLayout>
      <HomeHero />
      <About />,
      <WhatIsPassed />,
      <BannerIcon />,
      <HowItWorks />,
      <Feedbacks />,
      <FAQ />,
      <Pricing />,
      <Effect />,
      <Footer />
    </MainLayout>
  );
}
