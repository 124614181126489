import React from 'react';
import {
  Container, Typography, Box, Grid,
  useTheme,
} from '@material-ui/core';
import useMediaQuery from '@mui/material/useMediaQuery';
import WhatIsPassed from '../../Assets/WhatIsPassed.jpg';

function WebScreen(props) {
  return (
    <Box
      sx={{
        pt: 10,
        pb: 10,
        backgroundColor: 'background.paper',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Box
              sx={{
                height: '400px',
                background: `url(${WhatIsPassed})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '52.75px 0px',
                boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
              }}
              {...props}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h4"
              fontFamily="Cormorant SC"
              color="textPrimary"
              sx={{ pb: 5 }}
            >
              E o que é ensinado no treinamento ao mundo dos dados?
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              Nosso treinamento visa introduzir você ao pensamento analítico para
              data analysis. Iremos introduzir o ferramental estatístico e
              computacional para com uma estrutura lógica de dados,
              te mostrando passo a passo como entregar análises eficiêntes e objetivas.
            </Typography>
            <Typography
              sx={{ pt: 3 }}
              variant="body1"
              color="textPrimary"
            >
              Com as ferramentas certas, você apresentará resultados simples
              e consolidados para as pessoas de seu negócio.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function MobileScreen(props) {
  return (
    <Box
      sx={{
        pt: 10,
        pb: 10,
        backgroundColor: 'background.paper',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h4"
              fontFamily="Cormorant SC"
              color="textPrimary"
              sx={{ pb: 5 }}
            >
              E o que é ensinado no treinamento ao mundo dos dados?
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              Nosso treinamento visa introduzir você ao pensamento analítico para
              data analysis. Iremos introduzir o ferramental estatístico e
              computacional para com uma estrutura lógica de dados,
              te mostrando passo a passo como entregar análises eficiêntes e objetivas.
            </Typography>
            <Typography
              sx={{ pt: 3 }}
              variant="body1"
              color="textPrimary"
            >
              Com as ferramentas certas, você apresentará resultados simples
              e consolidados para as pessoas de seu negócio.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Box
              sx={{
                height: '400px',
                background: `url(${WhatIsPassed})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '52.75px 0px',
                boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
              }}
              {...props}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function WhatScreen() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (matches) {
    return <WebScreen />;
  }

  return <MobileScreen />;
}
export default function About(props) {
  return (
    <WhatScreen props={props} />
  );
}
