import React from 'react';
import {
  Container, Typography, Box, Grid,
} from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import AboutLaborem from '../../Assets/AboutLaborem.jpg';

export default function About(props) {
  return (
    <Box
      sx={{
        pt: 10,
        pb: 10,
        backgroundColor: 'background.default',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Fade bottom>
              <Typography
                variant="h4"
                fontFamily="Cormorant SC"
                color="textPrimary"
                sx={{ pb: 5 }}
              >
                Sobre a Laborem
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                Educação e Trabalho. Nosso lema traduz de forma simples e
                objetiva nosso manifesto ao que acreditamos.
              </Typography>
              <Typography
                sx={{ pt: 3 }}
                variant="body1"
                color="textPrimary"
              >
                Nossa dedicação objetiva te introduzir a
                nova cultura empresarial, a cultura data driven.
              </Typography>

            </Fade>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Fade right>
              <Box
                sx={{
                  height: '400px',
                  background: `url(${AboutLaborem})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: '52.75px 0px',
                  boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
                }}
                {...props}
              />
            </Fade>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
