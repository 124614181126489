import React, { useContext } from 'react';
import {
  Container, Typography, Box, Grid, Button, Link,
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { makeStyles } from '@material-ui/styles';
import { ThemeContext } from '../../../context/ThemeContext';

const pricingContent = [
  'Acesso completo a todas as aulas',
  'Ensino de ferramentas do básico ao avançado',
  'Suporte integral a suas dúvidas',
  'Materiais de apoio baseados em casos reais',
  'Comunidade ativa para complementar seus estudos',
];

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(91.49deg, #C31432 7.06%, #240B36 100%)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

function CustomButton() {
  const classes = useStyles();
  return <Button className={classes.root}>GARANTIR MINHA VAGA</Button>;
}
export default function HowItWorks(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        pt: 10,
        pb: 10,
        backgroundColor: 'background.default',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h4"
              fontFamily="Cormorant SC"
              color="textPrimary"
              sx={{ pb: 3 }}
            >
              Quanto custa tudo isso?
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            {
              pricingContent.map((content) => (
                <Box
                  display="flex"
                  sx={{
                    py: 1.5,
                  }}
                >
                  <Grid
                    xs={2}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CheckCircleOutlineIcon color="primary" fontSize="medium" />
                  </Grid>
                  <Grid
                    xs={10}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      variant="h6"
                      color="textPrimary"
                    >
                      {content}
                    </Typography>
                  </Grid>
                </Box>
              ))
            }
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Box>
              <Typography
                variant="h4"
                fontFamily="Cormorant SC"
                color="textPrimary"
              >
                Garanta sua vaga por apenas
              </Typography>
              <Typography
                variant="h1"
                fontFamily="Cormorant SC"
                sx={{
                  color: !theme ? 'primary.main' : '#fff',
                }}
              >
                12x R$127,00
              </Typography>
            </Box>
            <Box
              sx={{
                pt: 4,
              }}
            >
              <Link
                href="https://pay.hotmart.com/G67673936I?off=6oihnueq&checkoutMode=0&bid=1648488265798"
                target="_blank"
              >
                <CustomButton />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
