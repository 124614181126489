import React, { useContext } from 'react';
import {
  Container, Typography, Box, Grid,
} from '@material-ui/core';
import LiveTvOutlinedIcon from '@mui/icons-material/LiveTvOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import Fade from 'react-reveal/Fade';
import { ThemeContext } from '../../../context/ThemeContext';

export default function BannerIcon(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        pt: 8,
        pb: 8,
        backgroundColor: 'background.default',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Fade left big>
          <Grid
            container
            spacing={6}
            display="flex"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>
                <LiveTvOutlinedIcon
                  rect={{ fill: 'orange' }}
                  sx={{
                    height: '72px',
                    fontSize: 50,
                    color: !theme ? 'primary.main' : '#fff',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{
                    color: !theme ? 'primary.main' : '#fff',
                  }}
                >
                  Treinamentos ao vivo com data programada.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>
                <AutoGraphOutlinedIcon
                  sx={{
                    height: '72px',
                    fontSize: 50,
                    color: !theme ? 'primary.main' : '#fff',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{
                    color: !theme ? 'primary.main' : '#fff',
                  }}
                >
                  Estudos de casos reais com foco em problemas cotidianos.
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box>
                <GroupsIcon
                  sx={{
                    height: '72px',
                    fontSize: 50,
                    color: !theme ? 'primary.main' : '#fff',
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h6"
                  textAlign="center"
                  sx={{
                    color: !theme ? 'primary.main' : '#fff',
                  }}
                >
                  Turmas reduzidas, pensado para seu maior aprendizado.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </Box>
  );
}
