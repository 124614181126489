import React from 'react';
import {
  Container, Typography, Box, Button, Link,
  useTheme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import HomeHero from '../../Assets/HomeHero.jpg';
import Laborem from '../../Assets/SVG/Laborem.svg';
import ChangeTheme from '../../ChangeTheme';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(91.49deg, #C31432 7.06%, #240B36 100%)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

function CustomButton() {
  const classes = useStyles();
  return <Button className={classes.root}>GARANTIR MINHA VAGA</Button>;
}
export default function Home(props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: '100vh',
        background: `linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 100%), url(${HomeHero})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
      }}
      {...props}
    >
      <Container maxWidth="lg" sx={{ height: '100vh' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                pt: 10,
                mr: 5,
                [theme.breakpoints.between('xs', 'sm')]: {
                  pt: 5,
                },
              }}
            >
              <img
                src={Laborem}
                alt="Laborem"
                style={{
                  transform: 'scale(1.2)',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: 10,
                [theme.breakpoints.between('xs', 'sm')]: {
                  pt: 5,
                },

              }}
            >
              <Typography
                variant="h4"
                color="#fff"
                fontFamily="Cormorant SC"
                sx={{
                  [theme.breakpoints.between('xs', 'sm')]: {
                    fontSize: '6vw',
                  },
                }}
              >
                Instituto Laborem
              </Typography>
              <Typography
                variant="h5"
                color="#fff"
                sx={{
                  [theme.breakpoints.between('xs', 'sm')]: {
                    fontSize: '5vw',
                  },
                }}
              >
                Educação e Trabalho
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              pt: 10,
              [theme.breakpoints.between('xs', 'sm')]: {
                pt: 4,
              },
            }}
          >
            <ChangeTheme />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '80%',
          }}
        >
          <Typography
            variant="h1"
            color="#fff"
            textAlign="center"
            fontFamily="Cormorant SC"
            sx={{
              [theme.breakpoints.up('lg')]: {
                fontSize: '4vw',
              },
              [theme.breakpoints.between('sm', 'md')]: {
                fontSize: '8vw',
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: '12vw',
              },
            }}
          >
            Seja referência
          </Typography>
          <Typography
            variant="h1"
            textAlign="center"
            color="#fff"
            sx={{
              [theme.breakpoints.up('lg')]: {
                fontSize: '4vw',
              },
              [theme.breakpoints.between('sm', 'md')]: {
                fontSize: '8vw',
              },
              [theme.breakpoints.down('sm')]: {
                fontSize: '12vw',
              },
            }}
            fontFamily="Cormorant SC"
          >
            em data science
          </Typography>
          <Typography
            variant="h5"
            textAlign="center"
            fontWeight="400"
            color="#ccc"
            sx={{
              pt: 4,
            }}
          >
            Chegou o momento de você alavancar seus estudos,
            sua entrega, e seu futuro.
          </Typography>
          <Box
            sx={{
              pt: 6,
            }}
          >
            <Link
              href="https://pay.hotmart.com/G67673936I?off=6oihnueq&checkoutMode=0&bid=1648488265798"
              target="_blank"
            >
              <CustomButton />
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
