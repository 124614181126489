/* eslint-disable max-len */
import React from 'react';
import {
  Container, Typography, Box,
  useTheme, Link,
} from '@material-ui/core';
import Footer from '../Home/HomeComp/Footer';
import LaboremColor from '../Assets/LaboremColor.png';
import ChangeTheme from '../ChangeTheme';

export default function Privacy(props) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'background.paper',
          flexDirection: 'column',
        }}
        {...props}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Link
                href="/"
              >
                <Box
                  sx={{
                    pt: 10,
                    mr: 5,
                    [theme.breakpoints.between('xs', 'sm')]: {
                      pt: 5,
                    },
                  }}
                >
                  <img
                    src={LaboremColor}
                    alt="Laborem"
                    style={{
                      width: '80px',
                      color: 'red',
                    }}
                  />
                </Box>
              </Link>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  pt: 10,
                  [theme.breakpoints.between('xs', 'sm')]: {
                    pt: 5,
                  },

                }}
              >
                <Typography
                  variant="h4"
                  color="textPrimary"
                  fontFamily="Cormorant SC"
                  sx={{
                    [theme.breakpoints.between('xs', 'sm')]: {
                      fontSize: '6vw',
                    },
                  }}
                >
                  Instituto Laborem
                </Typography>
                <Typography
                  variant="h5"
                  color="textPrimary"
                  sx={{
                    [theme.breakpoints.between('xs', 'sm')]: {
                      fontSize: '5vw',
                    },
                  }}
                >
                  Educação e Trabalho
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                pt: 10,
                [theme.breakpoints.between('xs', 'sm')]: {
                  pt: 4,
                },
              }}
            >
              <ChangeTheme />
            </Box>
          </Box>
          <Box>
            <Typography variant="h2" color="textSecondary" sx={{ pt: 10 }}>
              Política de privacidade para Institutio et Laborem
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              Todas as suas informações pessoais recolhidas, serão usadas para o ajudar a tornar a sua visita no nosso site o mais produtiva e agradável possível.
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              A garantia da confidencialidade dos dados pessoais dos utilizadores do nosso site é importante para o Institutio et Laborem.
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              Todas as informações pessoais relativas a membros, assinantes, clientes ou visitantes que usem o Institutio et Laborem serão tratadas em concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro de 1998 (Lei n.º 67/98).
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              A informação pessoal recolhida pode incluir o seu nome, e-mail, número de telefone e/ou telemóvel, morada, data de nascimento e/ou outros.
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              O uso do Institutio et Laborem pressupõe a aceitação deste Acordo de privacidade. A equipa do Institutio et Laborem reserva-se ao direito de alterar este acordo sem aviso prévio. Deste modo, recomendamos que consulte a nossa política de privacidade com regularidade de forma a estar sempre atualizado.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h2" color="textSecondary" sx={{ pt: 3 }}>
              Os anúncios
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              Tal como outros websites, coletamos e utilizamos informação contida nos anúncios. A informação contida nos anúncios, inclui o seu endereço IP (Internet Protocol), o seu ISP (Internet Service Provider, como o Sapo, Clix, ou outro), o browser que utilizou ao visitar o nosso website (como o Internet Explorer ou o Firefox), o tempo da sua visita e que páginas visitou dentro do nosso website.
            </Typography>
          </Box>
          <Box>
            <Typography variant="h2" color="textSecondary" sx={{ pt: 3 }}>
              Cookie DoubleClick Dart

            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              O Google, como fornecedor de terceiros, utiliza cookies para exibir anúncios no nosso website;
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              Com o cookie DART, o Google pode exibir anúncios com base nas visitas que o leitor fez a outros websites na Internet;
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              Os utilizadores podem desativar o cookie DART visitando a Política de privacidade da rede de conteúdo e dos anúncios do Google.
            </Typography>
          </Box>
          <Box sx={{ mb: 10 }}>
            <Typography variant="h2" color="textSecondary" sx={{ pt: 3 }}>
              Ligações a Sites de terceiros

            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              O Institutio et Laborem possui ligações para outros sites, os quais, a nosso ver, podem conter informações / ferramentas úteis para os nossos visitantes. A nossa política de privacidade não é aplicada a sites de terceiros, pelo que, caso visite outro site a partir do nosso deverá ler a politica de privacidade do mesmo.
            </Typography>
            <Typography variant="h6" color="textSecondary" sx={{ pt: 3 }}>
              Não nos responsabilizamos pela política de privacidade ou conteúdo presente nesses mesmos sites.
            </Typography>
          </Box>
        </Container>
      </Box>
      <Footer />
    </>
  );
}
