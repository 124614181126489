import React from 'react';
import {
  Container, Typography, Box, Grid, Button, useTheme, Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// import { ThemeContext } from '../../../context/ThemeContext';
import BasicModal from './BasicModal';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(91.49deg, #C31432 7.06%, #240B36 100%)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
  outlined: {
    background: 'transparent',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'linear-gradient(91.49deg, #C31432 7.06%, #240B36 100%)',
    height: 48,
    padding: '0 30px',
  },
  outlinedDark: {
    background: 'rgba(255, 105, 135, .1)',
    border: 0,
    borderRadius: 8,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: '#fff',
    height: 48,
    padding: '0 30px',
  },
});

function CustomButton() {
  const classes = useStyles();
  return <Button className={classes.root}>COMEÇAR AGORA</Button>;
}
// function CustomVariant() {
//   const classes = useStyles();
//   const { theme } = useContext(ThemeContext);
//   if (!theme) {
//     return <Button className={classes.outlined}>ASSINE NOSSA NEWSLETTER</Button>;
//   }

//   return <Button className={classes.outlinedDark}>ASSINE NOSSA NEWSLETTER</Button>;
// }
export default function Effect(props) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        pt: 10,
        pb: 10,
        backgroundColor: 'background.paper',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            alignItems: 'center',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Grid>
              <Typography
                variant="h5"
                fontFamily="Roboto"
                fontStyle="italic"
                fontWeight="400"
                color="textPrimary"
                sx={{
                  pb: 4,
                }}
              >
                “Só se pode alcançar um grande êxito quando nos mantemos fiéis a nós mesmos.”
              </Typography>
              <Typography
                variant="h6"
                color="textPrimary"
                fontWeight="700"
                sx={{ pb: 0 }}
              >
                Friedrich Nietzsche
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              [theme.breakpoints.down('sm')]: {
                flexDirection: 'column',
                py: 2,
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          >
            <Grid
              sx={{
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  pt: 3,
                  alignItems: 'center',
                },
              }}
            >
              <Link
                href="https://pay.hotmart.com/G67673936I?off=6oihnueq&checkoutMode=0&bid=1648488265798"
                target="_blank"
              >
                <CustomButton />
              </Link>
            </Grid>
            <Grid
              sx={{
                display: 'flex',
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column',
                  pt: 3,
                  alignItems: 'center',
                },
              }}
            >
              <BasicModal />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
