import {
  Fab,
  Tooltip,
  Link,
} from '@material-ui/core';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function WppIcon() {
  return (
    <Tooltip title="Fale conosco">
      <Link
        href="https://api.whatsapp.com/send?phone=5511941868993"
        target="_blank"
      >
        <Fab
          size="large"
          sx={{
            bottom: 0,
            m: (theme) => theme.spacing(4),
            position: 'fixed',
            right: 0,
            zIndex: (theme) => theme.zIndex.speedDial,
          }}
        >
          <WhatsAppIcon fontSize="large" color="warning" />
        </Fab>
      </Link>
    </Tooltip>
  );
}

export default WppIcon;
