import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './components/Home/Home';
import Privacy from './components/Privacy/Privacy';

function CustomRoute({ isPrivate, ...rest }) {
  return <Route {...rest} />;
}

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/" component={Home} />
      <CustomRoute exact path="/privacy" component={Privacy} />
    </Switch>
  );
}
