import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { alpha, useTheme } from '@material-ui/core/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Fade from 'react-reveal/Fade';
import RemoveIcon from '@mui/icons-material/Remove';

const getFeatures = () => ([
  {
    icon: AddCircleOutlineIcon,
    items: ['Sim. Este treinamento visa capacitar qualquer um que passou pelo ensino médio a realizar análises sólidas que possam transformar dados em informação de valor para qualquer negocio.'],
    title: 'Eu não sou formado em áreas de exatas, posso fazer este treinamento?',
  },
  {
    icon: AddCircleOutlineIcon,
    items: [
      'Pela minha experiência ensinando, a programação não é dificuldades para os alunos. Principalmente com as novas ferramentais de análises que utilizamos.',
    ],
    title: 'Eu não sei programar, terei muita dificuldade neste treinamento?',
  },
  {
    icon: AddCircleOutlineIcon,
    items: ['Sim terá. Além dos 4 encontros semanais obrigatórios, você terá todas as aulas que foram ao vivo gravadas, referências para estudos e acesso ao fórum onde você poderá fazer perguntas sobre as suas dúvidas para serem respondidas.'],
    title: 'Terei suporte em minhas dificuldades?',
  },
]);
export default function FAQ(props) {
  const theme = useTheme();
  const features = getFeatures(theme.palette.mode);
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
  const handleSelectedFeatureIndexChange = (index) => {
    setSelectedFeatureIndex(index);
  };
  return (
    <Box
      sx={{
        pt: 8,
        pb: 8,
        backgroundColor: 'background.paper',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
          display="flex"
          justifyContent="space-between"
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography
                variant="h3"
                fontFamily="Cormorant SC"
                textAlign="center"
                color="textPrimary"
              >
                Perguntas Frequentes
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                textAlign="center"
                color="textPrimary"
              >
                Talvez uma dúvida anterior seja a sua, dê uma olhada
                nas perguntas que mais recebemos.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            sm={12}
            sx={{
              mt: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {features.map((feature, index) => {
              const {
                icon: Icon, items, title,
              } = feature;

              return (
                <Fade bottom duration={1200} delay={500}>
                  <Box
                    key={title}
                    onClick={() => handleSelectedFeatureIndexChange(index)}
                    sx={{
                      backgroundColor: index
                      === selectedFeatureIndex
                      && alpha(theme.palette.primary.main, 0.08),
                      borderRadius: 4,
                      cursor: index === selectedFeatureIndex
                        ? 'default'
                        : 'pointer',
                      display: 'flex',
                      mb: 2,
                      p: 2,
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: index === selectedFeatureIndex
                          ? 'primary.main'
                          : 'transparent',
                        color: index === selectedFeatureIndex
                          ? 'primary.contrastText'
                          : 'text.secondary',
                        mr: 2,
                      }}
                    >
                      <Icon fontSize="medium" />
                    </Avatar>
                    <div>
                      <Typography
                        color="textPrimary"
                        variant="h6"
                        fontWeight="700"
                      >
                        {title}
                      </Typography>
                      {index === selectedFeatureIndex && (
                      <List
                        disablePadding
                        sx={{
                          display: 'grid',
                          gridTemplateColumns: items.length > 4 && ({
                            sm: 'repeat(2, 1fr)',
                          }),
                          gap: 1,
                          pt: 2,
                        }}
                      >
                        {items.map((item) => (
                          <ListItem
                            disableGutters
                            key={item}
                            sx={{ py: 0 }}
                          >
                            <ListItemAvatar
                              sx={{
                                alignItems: 'center',
                                display: 'flex',
                                minWidth: 0,
                                mr: 0.5,
                              }}
                            >
                              <RemoveIcon color="primary" />
                            </ListItemAvatar>
                            <ListItemText
                              primary={(
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {item}
                                </Typography>
                              )}
                            />
                          </ListItem>
                        ))}
                      </List>
                      )}
                    </div>
                  </Box>
                </Fade>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
