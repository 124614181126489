import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { RetriveTheme } from './context/ThemeContext';
import useTheme from './hooks/useTheme';
import WppIcon from './components/Wpp';
import Routes from './routes';

function App() {
  const theme = useTheme();

  return (
    <RetriveTheme theme={theme}>
      <BrowserRouter>
        <CookieConsent
          location="top"
          buttonText="Aceitar"
          cookieName="LaboremCookie"
          style={{ background: '#0d0d0d' }}
          buttonStyle={{ color: '#000000', fontSize: '14px', boderRadius: '4px' }}
          expires={150}
        >
          <span style={{ fontSize: '16px', fontFamily: 'Roboto' }}>Esse site utiliza cookies para melhorar a sua experiência.</span>
        </CookieConsent>
        <WppIcon />
        <Routes />
      </BrowserRouter>
    </RetriveTheme>
  );
}

export default App;
