import React from 'react';
import {
  Container, Typography, Box, Grid,
} from '@material-ui/core';
import HowItWorkss from '../../Assets/HowItWorks.jpg';

export default function HowItWorks(props) {
  return (
    <Box
      sx={{
        pt: 10,
        pb: 10,
        backgroundColor: 'background.paper',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h4"
              fontFamily="Cormorant SC"
              color="textPrimary"
              sx={{ pb: 5 }}
            >
              E como funciona?
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              Nossa metodologia de ensino é ao vivo, teremos aulas marcadas 1 vez por semana com
              duração aproximada de 2h, podendo, em tempo real, te auxiliar e amparar. Mantemos
              também  todo o material gravado para que seja consultado sempre que necessário.
            </Typography>
            <Typography
              sx={{ pt: 3 }}
              variant="body1"
              color="textPrimary"
            >
              Te entregaremos os materiais de apoio necessário, assim como referências de
              estudo. Elas ficarão disponíveis e indicadas tanto nas aulas gravadas quanto ao
              vivo.
            </Typography>
            <Typography
              sx={{ pt: 3 }}
              variant="body1"
              color="textPrimary"
            >
              Você terá total acesso ao conteúdo durante 12 meses, e passado esse período,
              poderá renovar sua assinatura anual. Sendo nosso aluno, você terá acesso a
              descontos e bonus para os futuros treinamentos.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
          >
            <Box
              sx={{
                height: '400px',
                background: `url(${HowItWorkss})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '52.75px 0px',
                boxShadow: '0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)',
              }}
              {...props}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
