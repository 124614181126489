import React from 'react';
import {
  Container, Box, Grid, Typography, Link,
} from '@material-ui/core';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Laborem from '../../Assets/SVG/Laborem.svg';

export default function HowItWorks(props) {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Box
      sx={{
        pt: 2,
        pb: 2,
        background: 'linear-gradient(91.49deg, #C31432 7.06%, #240B36 100%)',
      }}
      {...props}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid
          container
          spacing={6}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: window.screen.width <= 600 ? 'center' : 'flex-start',
              alignItems: 'center',
            }}
          >

            <Box>
              <img
                src={Laborem}
                alt="Laborem"
                style={{
                  transform: 'scale(1.2)',
                }}
              />
            </Box>
            <Typography
              variant="body1"
              color="#fff"
              sx={{ pl: 4 }}
            >
              © Laborem, {year}
            </Typography>
            <Link
              href="/privacy"
            >
              <Typography
                variant="body"
                color="#ccc"
                sx={{
                  pl: 3,
                  fontSize: '12px',
                  fontFamily: 'Roboto',
                }}
              >
                Política de Privacidade.
              </Typography>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: window.screen.width <= 600 ? 'center' : 'flex-end',
              alignItems: 'center',
            }}
          >
            <Grid
              sx={{ px: 1.5 }}
            >
              <Link
                href="https://www.facebook.com/institutio.laborem.7"
                target="_blank"
                cursor="pointer"
              >
                <FacebookIcon
                  sx={{
                    fontSize: 40,
                    color: '#fff',
                  }}
                />
              </Link>
            </Grid>
            <Grid
              sx={{ px: 1.5 }}
            >

              <Link
                href="https://www.instagram.com/institutiolaborem/"
                target="_blank"
                cursor="pointer"
              >
                <InstagramIcon
                  sx={{
                    fontSize: 40,
                    color: '#fff',
                  }}
                />
              </Link>
            </Grid>
            <Grid
              sx={{ px: 1.5 }}
            >
              <Link
                href="https://www.linkedin.com/company/institutio-et-laborem/"
                target="_blank"
                cursor="pointer"
              >
                <LinkedInIcon
                  sx={{
                    fontSize: 40,
                    color: '#fff',
                  }}
                />
              </Link>
            </Grid>
            <Grid
              sx={{ px: 1.5 }}
            >
              <Link
                href="https://www.youtube.com/channel/UCLsTUdf5mQ1sSNNOYgEWa6g"
                target="_blank"
                cursor="pointer"
              >
                <YouTubeIcon
                  sx={{
                    fontSize: 40,
                    color: '#fff',
                  }}
                />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
