/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext } from 'react';
import { ThemeProvider } from '@material-ui/core';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import useTheme from '../hooks/useTheme';
import { THEMES } from '../constants';
import { themesOptions, baseOptions } from '../components/themes';

const ThemeContext = createContext();

function RetriveTheme({ children }) {
  const { theme, changeTheme } = useTheme();
  const themeVariants = themesOptions[theme ? THEMES.DARK : THEMES.LIGHT];
  const appliedTheme = createTheme(baseOptions, themeVariants);
  const themeIndex = responsiveFontSizes(appliedTheme);

  return (
    <ThemeContext.Provider value={{ theme, changeTheme }}>
      <ThemeProvider theme={themeIndex}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export { RetriveTheme, ThemeContext };
