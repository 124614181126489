import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness2RoundedIcon from '@mui/icons-material/Brightness2Rounded';
import { ThemeContext } from '../context/ThemeContext';

export default function ButtonThemes() {
  const { changeTheme, theme } = useContext(ThemeContext);
  const icon = !theme ? <WbSunnyIcon /> : <Brightness2RoundedIcon />;
  return (
    <IconButton
      edge="end"
      color="success"
      aria-label="mode"
      onClick={changeTheme}
      m={10}
    >
      {icon}
    </IconButton>
  );
}
