import React from 'react';
import { experimentalStyled } from '@material-ui/core/styles';

const MainLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  maxWidth: '100vw',
}));

function MainLayout({ children }) {
  return (
    <MainLayoutRoot
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      {children}
    </MainLayoutRoot>
  );
}
export default MainLayout;
